import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Toolbar,
  CssBaseline,
  Hidden,
  Drawer,
} from '@material-ui/core';
import Footer from 'components/Footer';
import LogoButton from 'components/nav components/LogoButton';
import HeaderText from 'components/nav components/HeaderText';
import MenuButton from 'components/nav components/MenuButton';
import HeaderLinks from 'components/nav components/HeaderLinks';
import MobileDrawer from 'components/nav components/MobileDrawer';
import HomeIcon from '@material-ui/icons/Home';
import EventIcon from '@material-ui/icons/Event';
import InfoIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    display: '-webkit-flex',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    width: '100%',
  },
}));

export default function Nav(props) {
  const { children, user } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const links = [
    { route: '#home', text: 'Home', icon: HomeIcon },
    { route: '#booking', text: 'Events', icon: EventIcon },
    { route: '#about', text: 'About', icon: InfoIcon },
    { route: '#contact', text: 'Contact', icon: EmailIcon },
    {
      route: '#sign-in',
      text: 'Google sign-in',
      in: LockOpenIcon,
      out: LockIcon,
    },
  ];

  const toggleDrawerState = () => {
    setOpen(prevState => !prevState);
  };

  return (
    <Box component='div' className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' elevation={0} className={classes.appBar}>
        <Toolbar>
          <LogoButton />
          <HeaderText />
          <Hidden mdUp implementation='css'>
            <MenuButton onClick={toggleDrawerState} />
          </Hidden>
          <Hidden smDown implementation='css'>
            <HeaderLinks links={links} user={user} />
          </Hidden>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor='left'
            open={open}
            onClose={toggleDrawerState}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <MobileDrawer
              open={open}
              links={links}
              user={user}
              toggleDrawer={toggleDrawerState}
            />
          </Drawer>
        </Hidden>
      </nav>
      <main id='home' className={classes.content}>
        <div className={classes.toolbar} />
        {children}
        <Footer />
      </main>
    </Box>
  );
}
