import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Container,
  Box,
  Typography,
  Button,
  SvgIcon,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { Zoom } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import backgroundMin from 'assets/backgroundMin.jpg';

const useStyles = makeStyles(theme => ({
  background: {
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${backgroundMin})`,
    backgroundSize: 'cover',
    WebkitBackgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  filter: {
    width: 'inherit',
    height: 'inherit',
    background: 'rgba(75,75,93,0.85)',
  },
  header: {
    height: 'calc(100vh - 55px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    display: '-webkit-flex',
    WebkitJustifyContent: 'center',
    WebkitAlignItems: 'center',
  },
  headerText: {
    fontWeight: 600,
    textShadow: '0 0 10px rgba(0,0,0,0.5)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    display: '-webkit-flex',
    WebkitFlexDirection: 'column',
  },
  icon: {
    width: '64px',
    marginBottom: theme.spacing(1),
  },
  iconHeader: {
    color: '#fff',
  },
  iconText: {
    color: 'rgba(255,255,255,0.6)',
  },
  svgIcon: {
    fontSize: 90,
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const items = [
    {
      header: 'Private Sessions',
      text: 'One on one skills training for just you',
      icon: PersonIcon, // privateSession
      grow: 1000,
    },
    {
      header: 'Small Groups',
      text: 'Skills training for small groups',
      icon: GroupIcon, // smallGroup
      grow: 1200,
    },
    {
      header: 'Clinics',
      text: 'Learn and develop skills with others',
      icon: GroupAddIcon, // clinic
      grow: 1400,
    },
  ];

  const openBooking = () => {
    window.location.href = '#booking';
  };

  return (
    <Box className={classes.background}>
      <Box className={classes.filter}>
        <Container className={classes.header}>
          <Grid
            container
            direction='row'
            justify='center'
            alignItems='center'
            style={{ width: '100%' }}
            spacing={10}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Zoom in={true} timeout={800}>
                <Typography
                  variant='h1'
                  component='h1'
                  align='center'
                  className={classes.headerText}
                >
                  Volleyball Skills Training
                </Typography>
              </Zoom>
            </Grid>

            {matches &&
              items.map((item, index) => (
                <Grid item key={index} md={4} lg={4}>
                  <Zoom in={true} timeout={900}>
                    <div className={classes.item}>
                      <SvgIcon
                        component={item.icon}
                        color='primary'
                        className={classes.svgIcon}
                      />
                      <Typography
                        variant='h3'
                        component='h3'
                        align='center'
                        className={classes.iconHeader}
                      >
                        {item.header}
                      </Typography>
                      <Typography
                        variant='body1'
                        component='p'
                        align='center'
                        className={classes.iconText}
                      >
                        {item.text}
                      </Typography>
                    </div>
                  </Zoom>
                </Grid>
              ))}

            <Grid item>
              <Zoom in={true} timeout={1000}>
                <Button
                  variant='contained'
                  size='large'
                  color='secondary'
                  onClick={openBooking}
                >
                  View Events
                </Button>
              </Zoom>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
