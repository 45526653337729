import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';

export default function MenuButton(props) {
  const { onClick } = props;
  return (
    <IconButton color='inherit' onClick={onClick}>
      <MenuIcon />
    </IconButton>
  );
}
